<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-datakey"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-datakey">
    <div>{{name}}</div>
    <div class="text-xs">{{description}}</div>
  </td>
</template>

<script>
  import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';

  export default {
    name: 'DataKeyField',
    mixins: [VuetableFieldMixin],
    computed: {
      name() {
        const getName = this.rowField.switch?.getName;
        return getName ? getName(this.rowData) : this.rowData.name;
      },
      description() {
        const getDescription = this.rowField.switch?.getDescription;
        return getDescription ? getDescription(this.rowData) : this.rowData.description;
      }
    },
  };
</script>
