<template>
  <div>
    <DetailsBar class="mb-10">
      <div>
      <label class="label">relationship type</label>
      <div>{{type}}</div>
      </div>
    </DetailsBar>

    <div class="flex items-center my-4">
      <span class="font-600 mr-2">shared authorized data blocks</span>
      <Tooltip icon="info" text="shared authorized data block can include records with preregistered shared data keys only."/>
    </div>

    <data-block-list></data-block-list>
  </div>
</template>

<script>
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Tooltip from "@/components/ui/Tooltip";
  import DetailsBar from '@/components/auth/details/DetailsBar';
  import DataBlockList from "./data-block/DataBlockList";

  export default {
  name: 'RelationshipTypeDetails',
  mixins: [ModalNavigation, NotifyMixin],
  components: {
    DataBlockList,
    DetailsBar,
    Tooltip,
  },
  computed: {
    type: function () {
      return this.$route.params.type;
    },
  },
  mounted() {
    this.setActiveModalTitle(`${this.type} relationship type`);
    this.setActiveModalBreadcrumbName(`${this.type} details`);
  }
};
</script>

<style scoped>

</style>
