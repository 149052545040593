<template>
  <List
    title="data blocks"
    resource="sharedDataBlocks"
    class="data-block-list"
    :basePath="basePath"
    :fields="fields"
    :request-params="{type: type}"
    ref="list"
    track-by="id"
    :pageSize="20"
    infinity-scroll
    :search="true"
    search-placeholder="Search for a data block by name, description"
    data-provider="$raaDataProvider"
    @row-clicked="handleRowClick"
  >
    <template v-slot:inline-actions="actionsScope">
      <button
        class="btn-action-warning focus:outline-none"
        type="button"
        @click.stop="deleteDataBlock(actionsScope.record)"
      >
        <icon class="w-5 h-5 mr-1" name="trash"/>
      </button>
    </template>
  </List>
</template>

<script>
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import List from "@/components/auth/list/List";
  import DataRecordsField from "@/components/auth/list/fields/DataRecordsField";
  import DataKeyField from "@/components/auth/list/fields/DataKeyField";
  import Icon from "@/components/ui/Icon";

  export default {
  name: 'DataBlockList',
  mixins: [ConfirmationMixin, NotifyMixin],
  components: {
    List,
    Icon,
  },
  data() {
    return {
      fields: [
        {
          name: DataKeyField,
          title: 'name',
          sortField: 'name',
        },
        {
          name: 'version',
          title: 'version',
          sortField: 'version',
        },
        {
          name: DataRecordsField,
          title: 'data records',
        },
        {
          name: 'inline-actions',
          title: '',
          width: "8%",
        }
      ],
    };
  },
  computed: {
    basePath: function () {
      return this.$route.path;
    },
    type: function () {
      return this.$route.params.type;
    },
  },
  methods: {
    handleRowClick({data: { name, version }}) {
      this.$router.replace({path: `${this.basePath}/data-block/${name}/version/${version}`});
    },
    deleteDataBlock({ name, version }) {
      this.requestConfirmation({
        confirmationMessage: 'Are you sure you want to delete this data block?',
        confirmBtnText: 'yes',
        cancelBtnText: 'no',
        confirmationType: 'warning'
      })
        .then(confirmed => confirmed
          ? this.$raaDataProvider.delete('sharedDataBlockVersioned', { name, version, type: this.type })
            .then(() => {
              this.$refs.list.doSearch();
              this.notifySuccess('data block is deleted');
            })
            .catch(error => this.notifyError(error.message))
          : null
        );
    },
  },
};
</script>

<style scoped>
  .data-block-list :deep(.vuetable-empty-result) {
    @apply text-sm text-center;
  }
</style>
