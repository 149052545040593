<template>
  <th
    v-if="isHeader"
    class="vuetable-th-component-records"
    v-html="title"
  />
  <td v-else class="vuetable-td-component-records">
    <ul>
      <li v-for="item in getKeyValue" :key="item.key">
        <span>{{item.key}}</span><span v-if="item.value">: {{item.value}}</span>
      </li>
    </ul>
 </td>
</template>

<script>
  import VuetableFieldMixin from 'vue3-vuetable/src/components/VuetableFieldMixin.vue';

  export default {
    name: 'DataRecordsField',
    mixins: [VuetableFieldMixin],
    computed: {
      getKeyValue() {
        const getKeyValue = this.rowField.switch?.getKeyValue;
        return getKeyValue ? getKeyValue(this.rowData) : this.rowData.records || [];
      },
    },
  };
</script>
